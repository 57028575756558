:root {
  --surface-color: #333;
  --text-color: #d7bdca;
  --curve: 40;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}
.author-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.author-card-list {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 20px 0;
  max-width: 600px;
  padding: 0 6vw;
  margin-bottom: 5rem;
}

.author-card {
  border-radius: calc(var(--curve) * 1px);
  box-shadow: 2.8px 2.8px 5px rgba(255, 255, 255, 0.479);
  
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  border: 2px solid white;
}

.author-card.-highlighted {
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 205, 245, 0.02),
    6.7px 6.7px 5.3px rgba(0, 205, 245, 0.028),
    12.5px 12.5px 10px rgba(0, 205, 245, 0.035),
    22.3px 22.3px 17.9px rgba(0, 205, 245, 0.042),
    41.8px 41.8px 33.4px rgba(0, 205, 245, 0.05),
    100px 100px 80px rgba(0, 205, 245, 0.07);
}

.author-card > .image {
  height: auto;
  width: 100%;
}

.author-card > .overlay {
  background-color: var(--surface-color);
  border-radius: calc(var(--curve) * 1px);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  transform: translateY(100%);
  transition: 0.3s ease-in-out;
  z-index: 1;
}

.author-card:hover > .overlay {
  transform: translateY(0);
}

.author-card > .overlay .header {
  align-items: center;
  background-color: var(--surface-color);
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  display: flex;
  gap: 2em;
  padding: 2em;
  position: relative;
  transform: translateY(-100%);
  transition: 0.3s ease-in-out;
}

.author-card > .overlay .arc {
  bottom: 100%;
  height: 80px;
  position: absolute;
  right: 0;
  width: 80px;
  z-index: 1;
}

.arc path {
  content: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
  fill: var(--surface-color);
}

.author-card:hover > .overlay .header {
  transform: translateY(0);
  
}

.author-card > .overlay .thumb {
  border-radius: 50%;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
}

.author-card > .overlay .title {
  color: #ffffff;
  font-size: 1em;
  margin: 0 0 0.3em;

}

.author-card > .overlay .status {
  color: var(--text-color);
  font-size: 0.8em;
}

.description-container {
  max-height: 150px;
  overflow-y: auto;
  padding: 0 2em 2em;
}

.description-container::-webkit-scrollbar {
  width: 6px;
}

.description-container::-webkit-scrollbar-thumb {
  background: var(--text-color);
  border-radius: 10px;
}

.description-container::-webkit-scrollbar-track {
  background: var(--surface-color);
}

.author-card > .overlay .description {
  color: var(--text-color);
  font-family: "MockFlowFont";
  margin: 0;
}

._invisible {
  display: none !important;
}

/* Social button CSS */

.social-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 20px;
}
.k2-button {
  background-color: #c8102e;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: "Almarai", serif;
}
.k2-social a {
  font-size: 25px;
  text-decoration: none;
  padding: 5px 5px;
  display: inline-block;
  transition: all 400ms;
}
.k2-social a:hover {
  transform: scale(1.1);
}
/* Official icon colors*/
.k2-social .fa-envelope {
  color: #3b5998;
}

.k2-social .fa-instagram {
  color: #c8102e;
}
.k2-social .fa-codepen {
  color: #446eac;
}
