/* wrongRoute.css */
.rs_notfound_container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    color: #f8f8f8;
    font-family: 'Consolas', monospace;
    padding: 20px;
  }
  
  .rs_notfound_content {
    max-width: 800px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .rs_notfound_title {
    font-size: 6rem;
    font-weight: 800;
    margin: 0;
    color: #f8f8f8;
    letter-spacing: -2px;
    position: relative;
  }
  
  .rs_notfound_glitch {
    display: inline-block;
    position: relative;
    color: #00ff9d;
    animation: glitch 1s infinite;
  }
  
  .rs_notfound_message {
    font-size: 1.5rem;
    margin: 0 0 10px 0;
    color: #cccccc;
  }
  
  .rs_notfound_submessage {
    font-size: 1.1rem;
    margin: 0 0 30px 0;
    color: #999999;
  }
  
  .rs_notfound_button {
    display: inline-block;
    padding: 12px 24px;
    background: linear-gradient(135deg, #00ff9d 0%, #00b8ff 100%);
    color: #111111;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-family: 'Consolas', monospace;
    position: relative;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 255, 157, 0.2);
  }
  
  .rs_notfound_button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: 0.5s;
  }
  
  .rs_notfound_button:hover::before {
    left: 100%;
  }
  
  .rs_notfound_button_text {
    position: relative;
    z-index: 1;
  }
  
  .rs_notfound_inline_code {
    background-color: #222222;
    padding: 2px 6px;
    border-radius: 3px;
    color: #00ff9d;
    font-family: 'Consolas', monospace;
  }
  
  .rs_notfound_code_container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 20px auto;
  }
  
  .rs_notfound_code_block {
    background-color: #1e1e1e;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
    text-align: left;
  }
  
  .rs_notfound_code_header {
    background-color: #2d2d2d;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #3c3c3c;
  }
  
  .rs_notfound_code_dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 6px;
  }
  
  .rs_notfound_code_dot:nth-child(1) {
    background-color: #ff5f56;
  }
  
  .rs_notfound_code_dot:nth-child(2) {
    background-color: #ffbd2e;
  }
  
  .rs_notfound_code_dot:nth-child(3) {
    background-color: #27c93f;
  }
  
  .rs_notfound_code_filename {
    color: #999999;
    margin-left: 10px;
    font-size: 0.9rem;
  }
  
  .rs_notfound_code_content {
    padding: 15px;
    overflow-x: auto;
  }
  
  .rs_notfound_code_content pre {
    margin: 0;
  }
  
  .rs_notfound_code_content code {
    font-family: 'Consolas', monospace;
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  .rs_notfound_code_keyword {
    color: #569cd6;
  }
  
  .rs_notfound_code_function {
    color: #dcdcaa;
  }
  
  .rs_notfound_code_symbol {
    color: #d4d4d4;
  }
  
  .rs_notfound_code_operator {
    color: #d4d4d4;
  }
  
  .rs_notfound_code_string {
    color: #ce9178;
  }
  
  .rs_notfound_code_comment {
    color: #6a9955;
  }
  
  .rs_notfound_code_error {
    color: #f44747;
  }
  
  .rs_notfound_code_indent {
    margin-left: 20px;
    display: inline-block;
  }
  
  @keyframes glitch {
    0% {
      transform: translateX(0);
      filter: hue-rotate(0deg);
    }
    10% {
      transform: translateX(-2px);
      filter: hue-rotate(90deg);
    }
    20% {
      transform: translateX(2px);
      filter: hue-rotate(180deg);
    }
    30% {
      transform: translateX(-2px);
      filter: hue-rotate(270deg);
    }
    40% {
      transform: translateX(2px);
      filter: hue-rotate(360deg);
    }
    50% {
      transform: translateX(0);
      filter: hue-rotate(0deg);
    }
    100% {
      transform: translateX(0);
      filter: hue-rotate(0deg);
    }
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .rs_notfound_title {
      font-size: 4rem;
    }
    
    .rs_notfound_message {
      font-size: 1.2rem;
    }
    
    .rs_notfound_submessage {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .rs_notfound_title {
      font-size: 3rem;
    }
    
    .rs_notfound_code_content code {
      font-size: 0.8rem;
    }
  }