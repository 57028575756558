.ncr_container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    position: relative;
    overflow: hidden;
    padding: 2rem;
}

/* Floating background elements */
.ncr_floating_element {
    position: absolute;
    border-radius: 3px;
    background: linear-gradient(135deg, #61dafb22, #764abc22);
    z-index: 1;
}

/* Main content card */
.ncr_card {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 800px;
    background: linear-gradient(135deg, #1a1a1a, #000000);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4),
                0 2px 5px rgba(0, 0, 0, 0.5),
                0 0 0 1px rgba(255, 255, 255, 0.1) inset;
}

.ncr_card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, #61dafb, #764abc, #f0db4f);
    z-index: 3;
}

.ncr_card_inner {
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

/* Illustration area */
.ncr_illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
}

.ncr_code_visual {
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
    color: #808080;
    font-family: 'Fira Code', monospace;
    font-size: 2.5rem;
    position: relative;
    z-index: 1;
}

.not-found-logo {
    width: 100px;
}

.ncr_code_bracket {
    margin: 0 10px;
    opacity: 0.6;
}

.ncr_code_lines {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
}

/* Content area */
.ncr_content {
    text-align: center;
    margin-bottom: 2rem;
    color: #e0e0e0;
}

.ncr_title {
    font-size: 2.5rem;
    margin: 0 0 1rem 0;
    background: linear-gradient(90deg, #61dafb, #764abc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-weight: 700;
}

.ncr_description {
    font-size: 1.2rem;
    margin: 0 0 2rem 0;
    color: #b0b0b0;
}

.ncr_instruction {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: rgba(97, 218, 251, 0.1);
    padding: 1rem;
    border-radius: 8px;
    border-left: 4px solid #61dafb;
    margin: 0 auto;
    max-width: 80%;
}

.ncr_instruction_icon {
    width: 24px;
    height: 24px;
    color: #61dafb;
    flex-shrink: 0;
}

/* Responsive styles */
@media (min-width: 768px) {
    .ncr_card_inner {
        padding: 3rem;
    }
    
    .ncr_instruction {
        max-width: 70%;
    }
}

@media (max-width: 600px) {
    .ncr_title {
        font-size: 2rem;
    }
    
    .ncr_description {
        font-size: 1rem;
    }
    
    .ncr_card_inner {
        padding: 1.5rem;
    }
    
    .ncr_instruction {
        max-width: 100%;
        flex-direction: column;
        text-align: center;
    }
}