/* Combined CSS File */

/* Global Reset and Root Variables */
:root {
  --bg: hsl(0 0% 2%);
  --color: hsl(0 0% 100% / 0.1);
  --underline-width: 1lh;
  --underline-block-width: 200vmax;
  --underline-color: hsl(0 0% 50% / 0.15);
  --underline-color-hover: hsl(180 100% 50% / 1);
  --underline-transition: 5s;
  --finish-fill: hsl(0 0% 100%);
  --accent: hsl(0 0% 100%);
  --fill: hsl(0 0% 50%);
  --fill2: hsl(0, 0%, 20%);
  --color2: hsl(0 0% 100% / 0.1);
}

html {
  width: 100%;
  height: 100%;
  background: var(--bg);
  overflow-x: hidden;
}

body {
  background-color: #080808 !important;
  color: var(--color);
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  accent-color: red;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

/* Layout Containers */
.container {
  width: 100%;
  max-width: 100%;
  padding: 0 1rem; /* Added slight padding for edge spacing */
  flex: 1;
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  position: relative;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/longBacround.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.4;
  z-index: 0;
  will-change: opacity;
}

/* Ensure content stays above pseudo-element */
.container > * {
  position: relative;
  z-index: 1;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

/* Text Alignments */
.ipsType_right,
.ipsType_left,
.ipsType_center {
  text-align: center;
}

.ipsType_right {
  text-align: right; /* Fixed to match class name intent */
  padding-right: 2rem; /* Added padding to keep text away from edge */
}

.ipsType_left {
  text-align: left;
  padding-left: 2rem; /* Consistent padding for left-aligned text */
}

/* Padding and Spacing */
.ipsType_right p,
.ipsType_left p {
  padding: 1rem 2rem; /* Increased padding for better spacing */
  margin: 0 1rem; /* Added margin for breathing room */
}

.ipsType_right p span,
.ipsType_left p span {
  font-size: clamp(0.9rem, 1.5vw, 1.75rem);
  color: #fff;
}

.ipsSpacer_bottom_double {
  margin-bottom: 2rem;
}

/* Grid System */
.ipsGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  width: 100%;
  max-width: 100%;
  padding: 0 1rem; /* Added padding to prevent edge crowding */
}

.ipsGrid > [class*="ipsGrid_span"] {
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}

.ipsGrid > .ipsGrid_span7 {
  width: 58%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ipsGrid > .ipsGrid_span5 {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Feature Section */
.feature {
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  position: relative;
  margin: 2rem 0;
  padding: 0 1rem; /* Added padding for consistency */
}

/* Content Section */
.content-section {
  padding: 0 2rem;
  position: relative;
  z-index: 1;
}

/* Typography */
h1 {
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: 600;
  background: linear-gradient(var(--finish-fill), var(--fill));
  background-clip: text;
  color: transparent;
}

h2.heading_large {
  font-size: clamp(2.5rem, 2vw, 2rem); /* Adjusted for better scaling */
  margin: 1rem 0;
  color: #fff;
}

.header-section {
  padding: 3rem 2rem; /* Increased padding for better spacing */
  font-size: clamp(1rem, 2vw, 1.5rem);
  color: #fff;
}

/* Lists */
.section1-list {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: clamp(1.2rem, 1.5vw, 1.75rem); /* Slightly smaller clamp */
}

/* Images */
.section1-image,
.section2-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  object-fit: contain;
}

.section2-image {
  transform: rotate(10deg);
  width: 100%;
  max-width: 600px;
}

/* Animations */
@keyframes your-animation {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.features {
  animation: your-animation 1s ease-in-out forwards;
}

@keyframes neon {
  0% {
    filter: brightness(270%);
  }
  100% {
    filter: brightness(40%);
  }
}

@keyframes shortCircuit {
  0%,
  4%,
  6% {
    opacity: 1;
  }
  2%,
  5% {
    opacity: 0.7;
  }
}

.neon-flash {
  animation: neon 1.5s ease-in-out infinite alternate,
    shortCircuit 5s ease-in-out 1.5s infinite;
}

/* Buttons */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem; /* Increased gap for better separation */
  padding: 1.5rem;
}

/* Buttons */
.btn-16 {
  position: relative;
  display: inline-block;
  min-width: 150px;
  padding: 0 1.5rem;
  height: 50px;
  line-height: 50px;
  color: #ffffff;
  font-size: clamp(14px, 1.5vw, 16px);
  font-family: "Inter", "Space Grotesk", sans-serif;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 10px;
  background: linear-gradient(135deg, #1e1e1e, #000000);
  border: 2px solid #444;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
}

/* Gradient overlay on hover */
.btn-16::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: left 0.5s ease;
  z-index: 1;
}

/* Border glow effect */
.btn-16::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #fb61d4, #d800a2);
  border-radius: 12px;
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: -1;
  filter: blur(5px);
}

/* Hover state */
.btn-16:hover {
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  border-color: #fb61d4;
}

.btn-16:hover::before {
  left: 100%;
}

.btn-16:hover::after {
  opacity: 1;
}

/* Active state */
.btn-16:active {
  transform: translateY(0);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-color: #ad00d8;
}

/* Focus state for accessibility */
.btn-16:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(251, 97, 218, 0.5);
}

/* Ensure button content stays above overlays */
.btn-16 > * {
  position: relative;
  z-index: 2;
}

/* Links */
.section1-chat {
  color: #61dafb;
  text-decoration: underline;
}

.section1-chat:hover {
  text-decoration: none;
}

/* Main button signup */
.getStartedButton {
  display: block;
  width: 320px;
  max-width: 90%;
  margin: 2rem auto;
  text-align: center;
  text-decoration: none;
  font-size: clamp(18px, 2vw, 24px);
  font-weight: 600;
  color: #333;
  background: #f0f0f0;
  border: 2px solid #333;
  border-radius: 50px;
  padding: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(233, 231, 231, 0.356);
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}

.getStartedButton:hover {
  color: white;
  background: #333;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.getStartedButton:active {
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #333;
}

/* Container for the welcome section */
.word-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  background: transparent;
  z-index: 1;
  overflow: hidden;
  padding: 60px 1rem 0; /* Adjusted padding for top and sides */
}

/* Wrapper for CrazyScrollPhrase */
.crazy-scroll-wrapper {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  text-align: center;
  z-index: 5;
  padding: 0 1rem; /* Added padding to prevent edge crowding */
}

/* Container for section images */
.sectionImages-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding: 0 1rem; /* Added padding for consistency */
}

/* Shake animation for elements */
@keyframes shake {
  0%,
  100% {
    transform: rotate(0);
  }
  5%,
  15%,
  25%,
  35%,
  45%,
  55%,
  65%,
  75%,
  85%,
  95% {
    transform: rotate(15deg);
  }
  10%,
  20%,
  30%,
  40%,
  50%,
  60%,
  70%,
  80%,
  90% {
    transform: rotate(-15deg);
  }
}

/* Main logo */
.projectschoolImg {
  max-width: clamp(200px, 40vw, 450px);
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  max-height: 80vh;
  object-fit: contain;
}

/* Decorative board image */
.sectionImg3 {
  max-width: clamp(300px, 60vw, 800px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  opacity: 0;
  object-fit: contain;
}

/* Small logo for mobile */
.pslogosmall {
  display: none;
  max-width: clamp(150px, 80%, 80%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  opacity: 1;
  object-fit: contain;
}

/* Main Header Styles */
.main-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  font-family: "Playfair Display", serif;
  font-size: clamp(1.5rem, 4vw, 5rem);
  font-weight: 900;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
}

/* Styling for 'React' span */
.main-header .react {
  color: #61dafb;
  font-weight: 700;
  animation: flash 3.5s infinite;
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.5;
  }
}

/* Highlight styling */
.main-header .highlight {
  font-family: "Syncopate", sans-serif;
  font-weight: 400;
  font-size: clamp(0.7rem, 1.5vw, 1.5rem);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #000000;
  background: #ffffff;
  padding: 0.5rem 1rem;
  display: inline-block;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

/* Hover effect for highlight */
.main-header .highlight:hover {
  background: #61dafb;
  color: #000000;
  cursor: pointer;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  h2.heading_large {
    font-size: clamp(1.3rem, 1.8vw, 1.8rem);
    padding-left: 1rem;
  }
  .section1-list {
    font-size: clamp(0.9rem, 1.3vw, 1.3rem);
  }
  
  .ipsGrid > .ipsGrid_span7 {
    width: 55%;
  }
  .ipsGrid > .ipsGrid_span5 {
    width: 40%;
  }
  .ipsType_right,
  .ipsType_left {
    padding: 0 1.5rem; /* Reduced padding slightly for medium screens */
  }
}

@media only screen and (max-width: 768px) {
  .container::before {
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
  }
  .ipsGrid > .ipsGrid_span7,
  .ipsGrid > .ipsGrid_span5 {
    width: 100%;
    text-align: center;
  }
  .ipsType_right p,
  .ipsType_left p {
    padding: 0.5rem 1rem; /* Adjusted padding for smaller screens */
    margin: 0 0.5rem;
  }
  .ipsType_right,
  .ipsType_left {
    padding: 0 1rem; /* Adjusted for smaller screens */
  }
  .section1-image {
    display: none;
  }
  .header-section {
    margin: 2rem auto;
    font-size: clamp(0.9rem, 1.5vw, 1.2rem);
    padding: 2rem 1rem;
  }
  .button-container {
    flex-direction: column;
    gap: 0.5rem;
  }
  .btn-16 {
    width: 100%;
    max-width: 300px;
  }
}

@media only screen and (max-width: 480px) {
  h2.heading_large {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    padding-left: 0.5rem;
  }
  .section1-list {
    font-size: clamp(0.8rem, 1vw, 1rem);
    margin: 0 0.5rem;
  }
  .ipsType_right p,
  .ipsType_left p {
    padding: 0.3rem 0.5rem; /* Further reduced padding */
    margin: 0 0.3rem;
  }
  .ipsType_right,
  .ipsType_left {
    padding: 0 0.5rem; /* Minimal padding for small screens */
  }
  .ipsType_right p span,
  .ipsType_left p span {
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  .section2-image {
    display: none;
  }
  .header-section {
    margin: 1.5rem auto;
    font-size: clamp(0.8rem, 1.2vw, 1rem);
    padding: 1.5rem 0.5rem;
  }
}

@media (max-width: 1024px) {
  .word-container {
    padding-top: 50px;
    height: 90vh;
  }
  .projectschoolImg {
    max-width: clamp(150px, 35vw, 350px);
  }
}

@media (max-width: 768px) {
  .word-container {
    padding-top: 40px;
    height: 100vh;
    position: relative;
  }
  .main-header .highlight {
    font-size: clamp(0.6rem, 1vw, 1rem);
    padding: 0.4rem 0.8rem;
  }
  .projectschoolImg {
    max-width: clamp(120px, 30vw, 300px);
    top: 55%;
  }
  .projectschoolImg,
  .sectionImg3 {
    display: none;
  }
   .pslogosmall {
    display: block;
    max-width: clamp(100px, 70%, 70%);
  }
  .crazy-scroll-wrapper {
    bottom: 1rem;
    padding: 0 0.5rem;
  }
}

@media (max-width: 480px) {
  .word-container {
    height: 100vh;
    position: relative;
    padding: 40px 0.5rem 0;
  }
  .projectschoolImg,
  .sectionImg3 {
    display: none;
  }
  .pslogosmall {
    display: block;
    max-width: clamp(100px, 70%, 70%);
  }
  .crazy-scroll-wrapper {
    bottom: 0.5rem;
    position: relative;
    padding: 0 0.5rem;
  }
}

@media (max-width: 375px) {
  .word-container {
    height: 100vh;
    position: relative;
    padding: 40px 0.3rem 0;
  }
}