.container {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
}

.carousel {  
  position: relative;
  width: 23rem;
  height: 23rem;
  perspective: 500px;
  transform-style: preserve-3d;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: 
    rotateY(calc(var(--offset) * 50deg)) 
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  filter: blur(calc(var(--abs-offset) * 1rem));
  transition: all 0.3s ease-out;
}

.nav {
  color: #8B5CF6;
  font-size: 5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
  transition: color 0.3s ease;
}

.nav.left {
  left: -5rem;
}

.nav.right {
  right: -5rem;
}

@media (max-width: 768px) {
  .carousel {
    height: 18rem;
  }

  .nav {
    font-size: 4rem;
  }

  .nav.left {
    left: -3rem;
  }

  .nav.right {
    right: -3rem;
  }
}

@media (max-width: 480px) {
  .carousel {
    width: 80%;
    height: 50vh;
  }

  .nav {
    font-size: 3rem;
  }

  .nav.left {
    left: -2rem;
  }

  .nav.right {
    right: -2rem;
  }
}

@media (max-width: 375px) {
  .carousel {
    width: 80%;
    height: 80vh;
  }

  .nav {
    font-size: 3rem;
  }

  .nav.left {
    left: -2rem;
  }

  .nav.right {
    right: -2rem;
  }

  .container {
    padding-bottom: 10rem;
  }
}
