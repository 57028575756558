#articles-main {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Piazzolla", serif;
  font-size: 20px;
  padding: 4rem 0;
}

article {
  width: 100%;
  padding: 0 10px;
}

.articles-section {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  height: auto;
}

.articles-section p {
  color: white;
  margin: 20px 0;
  text-align: start;
}

.articles-section ul {
  color: white;
  text-align: start;
  margin: 20px 0;
  list-style-type: disc;
}

.articles-section ol {
  text-align: left;
  text-align: start;
}

.articles-section h1 {
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  margin: 20px 0;
}
.articles-section .top-image {
  max-width: 100%;
  height: 300px;
  margin: 10px auto 0;
  border-radius: 15px;
}

.articles-section img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
  margin: 10px auto 0;
  border-radius: 5px;
}

blockquote {
  color: #3fd192;
  font-family: "Ubuntu", sans-serif;
  font-size: 25px;
}

.article-title {
  font-size: 1.75rem;
  font-weight: 800;
  text-decoration: underline;
  text-decoration-color: white;
  margin: 20px 0 10px 0;
}

.code-container-article {
  width: 100%;
  border-radius: 5px;
}

.code-container-article {
  width: 100%;
  border-radius: 5px;
  overflow-x: auto;
  background-color: #2d2d2d;
  margin: 20px 0;
}

.code-container-article pre {
  margin: 0;
  padding: 10px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.code-container-article button {
  margin-top: 10px;
  display: block;
  width: 100%;
  max-width: 120px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .code-container-article pre {
    width: 100%;
    font-size: 10px;
  }
}
