/* BlogCard.module.css */
.cardMasonry {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto 10rem;
}

.cardContainer {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  list-style: none;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.cardContainer:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 40px rgba(255, 192, 203, 0.15);
  border-color: rgba(245, 192, 255, 0.4);
}

.cardContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(45deg, 
    transparent,
    rgba(255, 192, 203, 0.03)
  );
  opacity: 0;
  transition: opacity 0.5s ease;
}

.cardContainer:hover::before {
  opacity: 1;
}

.cardContainerContent {
  padding: 2rem;
  position: relative;
  z-index: 1;
}

.cardHeaderTitle {
  color: #ffffff;
  font-size: 1.6rem;
  margin: 0 0 1rem 0;
  font-weight: 600;
  transition: all 0.3s ease;
  background: linear-gradient(90deg, #fff, #e0e0e0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.9;
  position: relative;
  display: inline-block;
}

.cardHeaderTitle::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 2px;
  background: rgb(250, 192, 255);
  transition: width 0.3s ease;
}

.cardContainer:hover .cardHeaderTitle {
  opacity: 1;
}

.cardContainer:hover .cardHeaderTitle::after {
  width: 100%;
}

.cardMainText {
  color: #a0a0a0;
  line-height: 1.7;
  margin-bottom: 1.8rem;
  font-size: 0.95rem;
  transition: color 0.3s ease;
}

.cardContainer:hover .cardMainText {
  color: #ffffff;
}

.cardButton {
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.3s ease;
  padding: 0.6rem 1.2rem;
  background: rgba(255, 192, 203, 0.05);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.cardButton:hover {
  background: rgba(248, 192, 255, 0.1);
  border-color: rgba(253, 192, 255, 0.3);
  padding-right: 1.8rem;
}

.cardButton::after {
  content: '→';
  margin-left: 0.5rem;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  opacity: 0;
  transform: translateX(-10px);
}

.cardButton:hover::after {
  opacity: 1;
  transform: translateX(0);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cardMasonry {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  
  .cardContainer {
    margin: 0 1rem;
  }
}

/* Staggered animation on page load */
@keyframes cardAppear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.cardContainer {
  animation: cardAppear 0.6s ease backwards;
}

.cardContainer:nth-child(2) {
  animation-delay: 0.1s;
}

.cardContainer:nth-child(3) {
  animation-delay: 0.2s;
}