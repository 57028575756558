:root {
  --bg: hsl(0 0% 2%);
  --color: hsl(0 0% 100% / 0.1);
  --underline-width: 1lh;
  --underline-block-width: 200vmax;
  --underline-color: hsl(0 0% 50% / 0.15);
  --underline-color-hover: hsl(180 100% 50% / 1);
  --underline-transition: 5s;
  --finish-fill: hsl(0 0% 100%);
  --accent: hsl(0 0% 100%);
  --fill: hsl(0 0% 50%);
  --fill2: hsl(0 0% 60%);
  --fill3: hsl(0, 0%, 75%);
}

/* Main Navigation */
nav {
  position: fixed;
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
  z-index: 9999;
  background: #080808;
  border-bottom: 4px solid var(--fill2);
  padding: 0;
  margin: 0;
}

.navbar-container {
  width: 100%;
  max-width: 100vw;
  z-index: 20000000;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0;
  margin: 0;
}

/* Middle Section and Dropdown */
.middle {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin: 0 2rem;
  position: relative;
}

.nav-dropdown {
  position: relative;
  display: inline-block;
  
}

.nav-link {
  color: var(--accent);
  font-size: 1.5rem;
  text-decoration: none;
  position: relative;
  background: linear-gradient(var(--finish-fill), var(--fill3));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: var(--accent);
  transition: width 0.5s ease;
}

.nav-link:hover::after {
  width: 100%;
}

/* Dropdown specific styles */
.nav-dropdown .nav-link::after {
  content: '▾';
  display: inline-block;
  margin-left: 0.5rem;
  transition: transform 0.2s ease;
  background: none;
  width: auto;
  height: auto;
  position: static;
}

.dropdown-link {
  display: block; /* Ensures the whole area is clickable */
  padding: 0.75rem 1rem;

  color: var(--accent);
  text-decoration: none;
  font-size: 1rem;
  transition: background 0.3s ease, color 0.3s ease;
  border-radius: 4px; /* Optional: Rounded corners */
}




.dropdown-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-width: 200px;
  background: var(--bg);
  border-radius: 8px;
  padding: 0.5rem;
  opacity: 0;
  visibility: hidden;
  transform-origin: top center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  z-index: 1000;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
 .dropdown-menu,
.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
}

.dropdown-item {
  display: block;
  color: var(--accent);
  text-decoration: none;
  font-size: 1rem;
  border-radius: 4px;
  transition: all 0.2s ease;
  white-space: nowrap;
  position: relative; 
}

.dropdown-item:hover {
  background: var(--fill3);
  transform: translateX(5px);
  color: var(--bg);
}

/* Sub-dropdown menu */
.sub-dropdown-menu {
  display: none;
  position: absolute;
  left: 100%;
  color: var(--accent);
  top: 0;
  background: var(--bg);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  min-width: 200px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.dropdown-item a:visited {
  color: var(--accent); /* Maintain the initial color */
}

/* Show sub-dropdown on hover */
.dropdown-item:hover .sub-dropdown-menu {
  display: block;
}

/* Styling for sub-dropdown items */
.sub-dropdown-item {
  display: block;
  padding: 0.75rem 1rem;
  color: var(--accent);
  text-decoration: none;
  font-size: 1rem;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.sub-dropdown-item:hover {
  background: var(--fill3);
  transform: translateX(5px);
  color: var(--bg);
}

/* Right Section */
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  color: var(--accent);
  gap: 25px;
}

/* Logo/Image */
nav img {
  width: 100%;
  height: 60px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: moveImage 1.5s ease forwards;
}

/* Button Styles */
.button-35 {
  align-items: center;
  background-color: var(--bg);
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px, rgba(18, 18, 18, 0.1) 0 6px 20px;
  color: var(--accent);
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border: 0;
  text-decoration: none;
  margin: 0;
}

.button-35:hover {
  box-shadow: var(--accent) 0 0 0 3px, transparent 0 0 0 0;
}

/* Burger Menu Icon */
.menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 35px;
  height: 30px;
  cursor: pointer;
}

.menu-icon__line {
  width: 100%;
  height: 3px;
  background-color: var(--accent);
  transition: transform 0.3s ease;
}

/* Responsive Menu */

.responsive-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  
}

.responsive-dropdown-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start */
  position: absolute;
  top: 0;
  right: 100%; /* Change from right to left */
  transform: translateY(0);
  background: var(--bg);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border: 1px solid var(--fill2);
  min-width: 200px; /* Ensure a minimum width */
}

.responsive-links {
  display: none;
  flex-direction: column;
  position: relative;
  top: 60px;
  right: 0;
  background-color: var(--bg);
  z-index: 999;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.responsive-links {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: var(--bg);
  z-index: 999;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}


.responsive-links.open {
  display: flex;
}

.responsive-links a {
  color: var(--accent);
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease, text-decoration 0.3s ease;
  font-size: 1rem;
}

.responsive-links a:hover {
  text-decoration: underline;
  text-decoration-color: var(--accent); 
  text-decoration-thickness: 2px; 
  text-underline-offset: 4px; 
}

.responsive-nav-link {
  font-size: 1.5rem;
  position: relative;
  background-clip: text;
  -webkit-background-clip: text;
  white-space: nowrap;
  padding: 0.5rem 1rem;
}

.responsive-nav-link::after {
  content: '▾'; 
  font-size: 1rem;
  position: absolute;
  right: -1.5rem; 
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease; 
}

/* Rotate the icon on hover */
.responsive-dropdown:hover .responsive-nav-link::after {
  transform: translateY(-50%) rotate(180deg);
}



/* Animations */
@keyframes moveImage {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

/* Responsive Styles */
@media only screen and (max-width: 460px) {
  .button-35 {
    padding: 0.3rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 8px;
  }

  .middle {
    display: none;
  }

  .menu-icon {
    display: flex;
  }

  nav img {
    width: 50px;
  }

  .userEmail {
    font-size: 0.75rem;
  }

  .menu-icon__line.open:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menu-icon__line.open:nth-child(2) {
    opacity: 0;
  }

  .menu-icon__line.open:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}

@media only screen and (max-width: 1024px) {
  .nav-link {
    font-size: 1rem;
  }

 
  .middle {
    display: none;
  }

  .menu-icon {
    display: flex;
  }

  .dropdown-menu {
    min-width: 160px;
  }

  .dropdown-item {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }
}

@media only screen and (min-width: 461px) and (max-width: 767px) {
  nav img {
    width: 150px;
  }

  .middle {
    display: none;
  }

  .menu-icon {
    display: flex;
  }
}