.card {
  position: absolute;
  width: 100%;
  padding: 2rem;
  background-color: hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%));
  border-radius: 1rem;
  color: #9CA3AF;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  min-height: 10rem; /* Minimum height */
  height: auto; /* Allow height to adjust based on content */
  max-height: 45rem; /* Maximum height to prevent extreme expansion */
  overflow: hidden;
  word-wrap: break-word;
  transform:
    rotateY(calc(var(--offset) * 50deg))
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  filter: blur(calc(var(--abs-offset) * 1rem));
}

.card h2 {
  text-align: left; /* Ensure the heading text is aligned properly */
  margin-bottom: 10px; /* Adjust the margin for proper spacing */
  font-size: 1.5rem; /* You can adjust the size as needed */
  line-height: 1.4; /* Set an appropriate line-height */
}

.card p {
  margin-top: 10px;
  line-height: 1.6;
  word-break: break-word;
  text-align: left;
  width: 100%;
}

.toggleButton {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #8b5cf6;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  align-self: center;
}

.toggleButton:hover {
  background-color: #7c3aed;
}

.toggleButton:active {
  transform: scale(0.98);
}