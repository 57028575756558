/* Global Styles */
* {
  font-family: "Lato", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Price Card Wrapper */
.card-wrapper {
  max-width: 350px;
  margin: 20px;
  border: 1px solid silver;
  border-radius: 10px;
  background-color: #000000;
  box-shadow: -4px 8px 12px rgba(179, 179, 179, 0.8);
  transition: transform 0.3s ease;
}

.card-wrapper:hover {
  transform: scale(1.05);
}

/* Card Header */
.card-header {
  text-align: center;
  margin: 20px 0;
}

/* Card Price Styling */
.card-price {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
}

.card-price.free {
  background-color: #7d7f8c;
}

.card-price.monthly {
  background-color: #369be6;
}

.card-price.annual {
  background-color: #4caf50;
}

.price-number {
  font-size: 36px;
  font-weight: 600;
}

/* Advantages List */
.advantages-list {
  padding: 20px;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.advantage-item {
  margin: 5px 0;
}

.crossed-text {
  text-decoration: line-through;
  color: #bbb;
}

/* Card Footer */
.card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

/* Card Button */
.card-button {
  width: 80%;
  background-color: #369be6;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.card-button:hover {
  background-color: #2a82b7;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

/* Terms Links */
.terms-links {
  text-align: center;
  color: white;
  padding: 20px;
}



.terms-input {
  margin-right: 10px;
}

.terms-links a {
  font-weight: bold;
  color: inherit;
  text-decoration: underline;
  font-size: 16px;
}

/* Price Cards Container */
.price-cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .price-cards-container {
    flex-direction: column;
    padding: 20px;
  }

  .card-wrapper {
    max-width: 100%;
    margin: 10px 0;
  }

  .card-price {
    height: 50px;
    font-size: 20px;
  }

  .advantages-list {
    font-size: 14px;
    padding: 15px;
  }

  .card-button {
    width: 90%;
    padding: 10px;
  }

  .terms-links {
    padding: 30px;
  }
}

/* Medium to Large Screens */
@media (min-width: 769px) {
  .price-cards-container {
    padding: 40px 60px;
  }

  .terms-links {
    padding: 50px 200px;
  }
}
