/* Set a fixed height for the cheatsheet popup */
.cheatsheet-popup {
  position: fixed;
  top: 10%; 
  left: 40%;
  max-width: 700px;
  transform: translate(-50%, 0); 
  height: 85vh; 
  background-color: #000;
  border-radius: 12px;
  box-shadow: 5px 5px 10px rgba(175, 175, 175, 0.349);
  padding: 40px; 
  color: white;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: center;
  border: #777 solid 0.1px;
  overflow-y: auto;
}

.cheatsheet-popup::-webkit-scrollbar {
  width: 8px;
}

.cheatsheet-popup::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.cheatsheet-popup::-webkit-scrollbar-track {
  background-color: #333;
}

.cheatsheet-content {
  display: flex;
  width: 100%;
  padding: 0;
}

.cheatsheet-text {
  flex: 2;
  padding-right: 20px;
}

.cheatsheet-text h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  text-decoration: underline;
}

.cheatsheet-text h2 {
  font-size: 18px;
  font-weight: bold;
  color: #ddd;
  margin-bottom: 10px;
}

.cheatsheet-text ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cheatsheet-text li {
  font-size: 14px;
  padding: 5px 0;
  margin: 0;
  list-style-type: disc;

}

.cheatsheet-image {
  border-radius: 15px;
  flex: 1;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  margin-left: 20px;
  object-fit: cover;
}

.cheatsheet-close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cheatsheet-close-button:hover {
  background-color: #333;
  color: #fff;
}


@media screen and (max-width: 768px) {
  .cheatsheet-popup {
    max-width: 500px;
    left: 20%;
  }
}



