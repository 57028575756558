

.success-container {
  text-align: center;
  padding: 40px 0;
  background: #000000;
  height: 100vh;
}

.success-text {
    color: #fff;
}

i.checkmark {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.success-card {
  background: rgb(0, 0, 0);
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 60px auto;
}
.icon-container {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #F8FAF5;
  margin: 0 auto;
}

.cancel-container {
  text-align: center;
  padding: 40px 0;
  background: #000000;
  height: 100vh;
}

.cancel-checkmark {
  color: #cc3419;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.cancel-card {
  background: rgb(0, 0, 0);
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 60px auto;
}

.cancel-icon-container {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #faa4a4;
  margin: 0 auto;
}

