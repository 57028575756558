.solution-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
  }

  .custom-code-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    border-top: 1px solid #555;
    border-bottom: 1px solid #555;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: auto; /* Added */
    word-break: break-all; /* Added */
  }
  
  .copy-button {
    margin-top: 10px;
    text-align: center;
  }

  .button-84.copied {
    background: linear-gradient(#71df75, #29862d);
    animation: pulse 0.5s;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .button-85 {
    margin-right: 3rem;
    background: linear-gradient(#464d55, #25292e);
    border-radius: 8px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    padding: 8px 16px;
    transition: all 0.3s ease;
    z-index: 999;
  }
  
  .button-85:hover {
    box-shadow: rgba(231, 155, 155, 0.2) 0 2px 8px;
    opacity: 0.85;
  }

 
  .solution-title {
    text-align: center;
    word-wrap: break-word;
    padding: 10px 0;
  }
  

  @media only screen and (max-device-width: 769px) {
    .button-85 {
      top: 5rem;
    left: 2rem;

    }
   
  }
  
  