/* Wrapper for the card grid */
.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 2.5rem;
    padding: 2rem;
    max-width: 1000px;
    margin: 0 auto 10rem auto;
  }
  
  /* Exercise card container */
  .exercises-card {
    position: relative;
    background: #151515;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
    border: 2px solid rgba(255, 255, 255, 0.1);
    animation: cardAppear 0.6s ease backwards;
  }
  
  .exercises-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 40px rgba(255, 192, 203, 0.15);
    border-color: rgba(245, 192, 255, 0.4);
  }
  
  .exercises-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(45deg, transparent, rgba(255, 192, 203, 0.03));
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .exercises-card:hover::before {
    opacity: 1;
  }
  
  /* Card body content */
  .exercises-card__body {
    padding: 2rem;
    position: relative;
    z-index: 1;
  }
  
  /* Card image */
  .exercises-card__image {
    padding: 25px;
    height: 15rem;
    object-fit: contain; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; 
    transition: transform 0.5s ease;
  }
  
  .exercises-card:hover .exercises-card__image {
    transform: scale(1.05);
  }
  
  .exercises-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
    font-size: 2rem;
  }
  /* Card title */
  .exercises-card__title {
    color: #ffffff;
    font-size: 1.6rem;
    margin: 0 0 1rem 0;
    font-weight: 600;
    transition: all 0.3s ease;
    background: linear-gradient(90deg, #fff, #e0e0e0);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.9;
    position: relative;
    display: inline-block;
  }
  
  .exercises-card__title::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 2px;
    background: rgb(250, 192, 255);
    transition: width 0.3s ease;
  }
  
  .exercises-card:hover .exercises-card__title {
    opacity: 1;
  }
  
  .exercises-card:hover .exercises-card__title::after {
    width: 100%;
  }
  
  /* Card description */
  .exercises-card__description {
    color: #a0a0a0;
    line-height: 1.7;
    margin-bottom: 1.8rem;
    font-size: 0.95rem;
    transition: color 0.3s ease;
  }
  
  .exercises-card:hover .exercises-card__description {
    color: #ffffff;
  }
  
  /* Start button */
  .exercises-card__btn {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    transition: all 0.3s ease;
    padding: 0.6rem 1.2rem;
    background: rgba(255, 192, 203, 0.05);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  
  .exercises-card__btn:hover {
    background: rgba(248, 192, 255, 0.1);
    border-color: rgba(253, 192, 255, 0.3);
    padding-right: 1.8rem;
  }
  
  .exercises-card__btn::after {
    content: '→';
    margin-left: 0.5rem;
    transition: all 0.3s ease;
    font-size: 1.2rem;
    opacity: 0;
    transform: translateX(-10px);
  }
  
  .exercises-card__btn:hover::after {
    opacity: 1;
    transform: translateX(0);
  }
  
  /* Ripple effect */
  .ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    transform: scale(0);
    animation: ripple 0.6s linear;
    pointer-events: none;
  }
  
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
  
  /* Card appear animation */
  @keyframes cardAppear {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Staggered animation delays */
  .exercises-card:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .exercises-card:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .wrapper {
      grid-template-columns: 1fr;
      padding: 1rem;
    }
    
    .exercises-card {
      margin: 0 1rem;
    }
  }
  
  
  @media (max-width: 375px) {
    .exercises-title {
      margin-top: 50px;
      font-size: 2rem;
    }
  }
  
  