/* Existing root and global styles remain unchanged */
html {
  width: 100%;
  height: 100vh;
  background: var(--bg);
}

.Toastify__toast-container {
  position: fixed;
  top: 80px !important;
  z-index: 9999;
}

:root {
  --bg: hsl(0 0% 2%);
  --color: hsl(0 0% 100% / 0.1);
  --underline-width: 1lh;
  --underline-block-width: 200vmax;
  --underline-color: hsl(0 0% 50% / 0.15);
  --underline-color-hover: hsl(180 100% 50% / 1);
  --underline-transition: 5s;
  --finish-fill: hsl(0 0% 100%);
  --accent: hsl(0 0% 100%);
  --fill: hsl(0 0% 50%);
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --ring: 222.2 84% 4.9%;
  --radius: 0.5rem;
}

.dark {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;
  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;
  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;
  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;
  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;
  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;
  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;
  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;
  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;
  --ring: 212.7 26.8% 83.9%;
}

body {
  background-color: #080808 !important;
  color: var(--color);
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  accent-color: red;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(var(--finish-fill), var(--fill));
  background-clip: text;
  color: transparent;
}

h2 {
  font-weight: 600;
  background: linear-gradient(var(--finish-fill), var(--fill));
  background-clip: text;
  color: transparent;
}

h3 {
  font-weight: 420;
  color: #fff;
  padding-bottom: 20px;
}

h6 {
  font-size: 30px;
  font-weight: 600;
  background: linear-gradient(var(--finish-fill), var(--fill));
  background-clip: text;
  color: transparent;
  margin: 10px;
}

.bold-article-paragraph {
  font-size: 25px;
  font-weight: 600;
  text-align: start;
  background: var(--finish-fill);
  background-clip: text;
  color: transparent;
  margin: 10px;
  display: block;
}

p > span {
  font-size: 2rem;
  color: white;
}

section {
  position: sticky;
  top: 0;
  width: 100vw;
  display: grid;
}

/* Button 28 */
.button-28 {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 5px;
  min-height: 20px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button-28:hover {
  color: #000000;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28.complete {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.button-28.complete:hover {
  background-color: #4efd54;
}

.button-28.completed {
  background: linear-gradient(#71df75, #29862d);
}

.button-28.completed:hover {
  background-color: #dd1818;
}

.button-28.previous,
.button-28.next {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Main button signup */
.getStartedButton {
  display: block;
  width: 320px;
  max-width: 100%;
  margin: 30px auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  background: #f0f0f0;
  border: 2px solid #333;
  border-radius: 50px;
  padding: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(233, 231, 231, 0.356);
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}

.getStartedButton:hover {
  color: white;
  background: #333;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.getStartedButton:active {
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.getStartedButton::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: -1;
  pointer-events: none;
}

.getStartedButton:hover::before {
  opacity: 1;
  animation: shine 1.5s infinite;
}

@keyframes shine {
  0% {
    transform: rotate(45deg) translate(-50%, -50%);
  }
  100% {
    transform: rotate(45deg) translate(50%, 50%);
  }
}

/* Video popup */
.video-popup-window {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 70%;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 25px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 100000;
  border: #777 solid 0.1px;
  padding: 20px;
  box-shadow: 5px 5px 10px rgba(175, 175, 175, 0.349);
  align-items: center;
  justify-content: center;
}

.video-container {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container iframe {
  height: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: none;
}

.video-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
}

/* Button 84 */
.button-84 {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(#111111, #25292e);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  width: 175px;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  height: 32px;
  justify-content: center;
  line-height: 1;
  margin: 10px;
  outline: none;
  overflow: hidden;
  padding: 0 22px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-84:hover {
  box-shadow: rgba(0, 1, 0, 0.2) 0 2px 8px;
  opacity: 0.85;
}

.filter-dropdown {
  background-color: #464d55;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #25292e;
  padding: 8px 15px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  font-family: expo-brand-demi, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  transition: all 150ms;
  margin-left: 10px;
  margin-right: 10px;
}

.filter-dropdown:hover {
  background-color: #25292e;
}

.filter-dropdown:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 0 3px;
}

.sort {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.sort h4 {
  color: #fff;
  margin-right: 15px;
}

@media (max-width: 600px) {
  .filter-dropdown {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    margin-left: 0;
    margin-right: 0;
  }
  .sort {
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  .sort h4 {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .button-81 {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}

@media (max-width: 420px) {
  .button-84 {
    height: 28px;
    width: 80%;
    font-size: small;
  }
}

@media only screen and (max-device-width: 1440px) {
  .button-28 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
  }
  .button-28::after {
    font-size: 25px;
  }
  .button-28.previous::after {
    content: "\2039";
  }
  .button-28.complete::after {
    content: "\2713";
  }
  .button-28.next::after {
    content: "\203A";
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #333;
}
