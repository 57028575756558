/* correctCode card */
.correctCode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

.correctCode-card {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 40px 30px;
    margin: 0 auto;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.correctCode-card h2 {
    width: 500px;
    font-size: 1.6rem;
    word-wrap: break-word;
}


/* correctCode Buttons */
.correctCode-btn {
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0;
    background-color: #333;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.correctCode-btn:hover,
.correctCode-btn:active {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.correctCode-btn:disabled {
    background: #555;
    cursor: not-allowed;
    box-shadow: none;
}

.correctCode-btn.correct {
    background-color: #32cd32; /* Green */
}

.correctCode-btn.incorrect {
    background-color: #e74c3c; /* Red */
}

/* Code Container for Scrollable Code */
.code-container {
    width: 100%;
    max-width: 100%;  /* Ensure it takes full width of the button */
    overflow-x: auto; /* Allow horizontal scroll */
    white-space: nowrap; /* Prevent wrapping of the code */
}

/* Feedback */
.feedback {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 20px;
    color: #fff;
    animation: fadeIn 1s ease-in;
}

/* correctCode Progress Bar Container */
.correctCode-progress-bar {
    margin-top: 20px;
    height: 10px;
    width: 100%;
    background-color: #2a2a2a;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
}

/* correctCode Progress */
.correctCode-progress {
    height: 100%;
    background: linear-gradient(90deg, #32cd32, #00ffcc);
    transition: width 0.5s ease-in-out;
    animation: progressGlow 1s infinite alternate;
}

/* correctCode Result */

.correctCode-result, .correctCode-result h2  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px; 
}

/* Next Button */
.next-btn,
.retry-btn {
   
    padding: 14px 28px;
    font-size: 1.2rem;
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.next-btn:hover,
.retry-btn:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
}

.retry-btn {
    background-color: #28a745;
}

.retry-btn:hover {
    background-color: #218838;
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes progressGlow {
    from {
        box-shadow: 0 0 10px rgba(50, 205, 50, 0.5);
    }
    to {
        box-shadow: 0 0 20px rgba(0, 255, 204, 0.7);
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .correctCode {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 30px);

     }
    .correctCode-card {
        padding: 30px 20px;
        max-width: 90%;
    }

    .correctCode-card h2 {
        width: 100%;  
        font-size: 1rem;
        word-wrap: break-word; /* Prevent overflow */
    }

    .correctCode-btn {
        font-size: 1rem;
        padding: 14px 16px;
    }

    .next-btn,
    .retry-btn {
        font-size: 1rem;
        padding: 12px 20px;
    }

    .correctCode-progress-bar {
        height: 8px;
    }
}

@media (max-width: 480px) {
     .correctCode {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 30px);

     }
   
    pre {
        margin: 0;
        font-size: 0.7rem;
        
    }
    .correctCode-card {
        padding: 20px 15px;
        
    }

    .correctCode-card h2 {
        font-size: 1rem;
    }

    .correctCode-btn {
       
        font-size: 0.9rem;
        padding: 10px;
    }

    .correctCode-result, .correctCode-result h2  {
        padding: 10px;
    }

    .next-btn,
    .retry-btn {
        font-size: 0.9rem;
        padding: 10px 16px;
    }

    .correctCode-progress-bar {
        height: 6px;
    }

    .correctCode-progress {
        animation: none; 
    }

    .feedback {
        font-size: 1.2rem;
        text-align: center; 
    }
}

