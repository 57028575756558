.main {
  display: block;
  width: 100%;
  height: 100vw;
  background-color: rgba(0, 0, 0, 0.08);
}

.faq-container {
  padding: 100px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.faq-title {
  display: block;
  position: relative;
  font-size: 2rem;
}

.faqContainer {
  width: 100%;
  max-width: 900px;
  margin: 20px 0;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 24px;
  line-height: 150%;
  text-transform: capitalize;
}

.faqItemContainer {
  width: 100%;
  max-width: 700px;
  background-color: white;
  margin-bottom: 8px;
  border-radius: 8px;
  text-align: left;
  text-transform: none;
  overflow: hidden;
  padding: 16px;
  transition: all 300ms;
}

.faqHeader {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  line-height: 100%;
  color: #000;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-right: 8px;
}

.arrow.open {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.faqBody {
  margin-top: 8px;
  font-size: 14px;
  line-height: 150%;
  color: #757575;
}

.faq-container-answer {
  padding: 10px;
}

.faq-container-answer p {
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
}

/* Responsive styles */
@media only screen and (max-width: 1024px) {
  .faq-title {
    font-size: 5rem;
  }
  
 
  .faqItemContainer {
    width: 80%;
    padding: 16px;
  }
  
  .faqBody {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .faq-title {
    font-size: 4rem;
  }
  
 

  .faqItemContainer {
    width: 90%;
  }
  
  .faqBody {
    font-size: 14px;
  }
  
  .faqHeader {
    font-size: 1.1rem;
  }
  
  .faq-container-answer p {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 480px) {
  .faq-title {
    font-size: 3rem;
  }

  .faqItemContainer {
    width: 100%;
  }

  .faqHeader {
    font-size: 1rem;
  }
  
  .faqBody {
    font-size: 12px;
  }
  
  .faq-container-answer p {
    font-size: 0.85rem;
  }
}
