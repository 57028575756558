.container {
  display: flex;
  flex-direction: row; /* Ensure the layout stays vertical */
  justify-content: center; /* Align items at the top */
  align-items: stretch; /* Ensure full width */
  padding: 20px;
  min-height: 100vh; /* Ensure the container takes the full height */
  overflow-y: none; /* Allow the content to scroll when it exceeds the container height */
}

.questionCard, .codeEditor {
  width: 30%; /* Full width for cards */
  padding: 20px;
  height: 100%;
  border: 1px solid #333;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  color: #fff;
  margin: 20px;
}

.textArea {
  width: 100%;
  min-height: 400px;
  height: auto;
  border: none;
  outline: none;
  resize: none;
  font-size: 16px;
  padding: 10px;
  font-family: monospace;
  background-color: #333;
  color: #fff;
  border-radius: 8px;
}

.feedback {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 8px;
  background-color: #f8f9fa;
  margin-bottom: 20px;
}

.feedback.correct {
  color: #30a74b;
}

.feedback.error {
  color: #dc3545;
}

.runButton {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.runButton:hover {
  background-color: #218838;
}



.navigationButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.button {
  padding: 10px 20px;
  background-color: #444;
  color: #fff;
  border: 1px solid #555;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.button:hover {
  background-color: #555;
  border-color: #888;
  transform: scale(1.05);
}

.button:active {
  background-color: #666;
  border-color: #aaa;
}


@media (max-width: 1024px) {
  .container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    justify-content: start;
  }

  .questionCard, .codeEditor {
    width: 80%; /* Cards take more width on small screens */
    font-size: 1.5rem;
  }

  .navigationButtons {
    justify-content: space-evenly; /* Center navigation buttons */
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }

  .questionCard, .codeEditor {
    width: 90%; /* Further reduce the card width on very small screens */
    font-size: 1rem;

  }

  .textArea {
    min-height: 300px;
  }
}

@media (max-width: 375px) {
  .container {
    flex-direction: column; /* Ensure column layout on very small screens */
    align-items: center;
  }

  .questionCard, .codeEditor {
    width: 90%; /* Cards take more width on extra small screens */
  }

  .runButton, .button {
    width: 100%; /* Make buttons full width */
    font-size: 13px; /* Adjust font size */
  }

  .navigationButtons {
    flex-direction: column; /* Stack navigation buttons vertically */
  }
}