/* exercises.css */

h1,
h2,
h3,
h5 {
  margin: 0;
}

.header {
  text-align: center;
  padding: 2rem;
}

.lessons-cards {
  display: grid; 
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); 
  gap: 2rem; 
  padding: 2rem;
  max-width: 100%; 
  margin: 0 auto 10rem auto; 
}

.lessons-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  list-style: none;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.lessons-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 40px rgba(255, 192, 203, 0.15);
  border-color: rgba(245, 192, 255, 0.4);
}

.lessons-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(45deg, transparent, rgba(255, 192, 203, 0.03));
  opacity: 0;
  transition: opacity 0.5s ease;
}

.lessons-card:hover::before {
  opacity: 1;
}

.lessons-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: relative;
  z-index: 1;
}

.lessons-card img {
  width: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  transition: transform 0.5s ease;
  border-radius: 5px;
}

.lessons-card:hover img {
  transform: scale(1.05); 
}

.lessons-card h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.lessons-card h3 {
  margin-top: 10px;
  text-align: center;
}

.lessons-card-body p {
  color: #a0a0a0;
  line-height: 1.7;
  margin-bottom: 1.8rem;
  font-size: 0.95rem;
  transition: color 0.3s ease;
}

.lessons-card:hover .lessons-card-body p {
  color: #ffffff;
}

.unavailable {
  filter: blur(5px);
}

/* Media Queries */
@media (max-width: 768px) {
  .lessons-cards {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 480px) {
  .lessons-cards {
    grid-template-columns: 1fr; 
  }
}
