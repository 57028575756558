.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 80px 60px;
    font-family: Arial, sans-serif;
  }
  
  .privacy-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-header h1 {
    font-size: 2.5rem;
  }
  
  .privacy-header p {
    font-size: 1rem;
    color: #666;
  }
  
  .privacy-content {
    line-height: 1.6;
  }
  
  .privacy-content h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-content p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: white;
  }
  
  .privacy-content ul {
    list-style-type: disc;
    margin-left: 20px;
    color: white;
  }
  
  address {
    margin-top: 20px;
    font-style: normal;
    color: white;
  }
  

  