.editor-container {
  display: flex;
  width: 100%;
}

.text-window::-webkit-scrollbar {
  width: 8px;
}

.text-window::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.text-window::-webkit-scrollbar-track {
  background-color: #333;
}

.task-container {
  display: flex;
  flex-direction: column;
  width: 20%; 
  min-width: 200px; 
  max-width: 80%; 
  height: calc(100vh - 60px);
  background-color: #000000;
  border: 1px solid #ccc;
  color: #fff;
  margin-top: 60px;
  resize: horizontal; 
  overflow: auto; 
}

.task {
  flex: 1;
  overflow: auto;
}

.task-buttons {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.editor-container iframe {
  margin-top: 60px;
  width: calc(100% - 30%);
  flex-grow: 1; 
}

.text-window {
  width: 100%; 
  height: 100%;
  overflow: auto;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  box-sizing: border-box;
}

.text-window p {
  padding: 0 0 0 25px;
  font-weight: 800;
  word-wrap: break-word; 
}

.text-window h1 {
  
  text-align: center;
}
.text-window h3 {
  margin: 20px 0;
}

.text-window h1,
.text-window h3,
.text-window h4 {
  padding: 10px;
  word-wrap: break-word; 
}
.section p {
  font-weight: 400;
  word-wrap: break-word;
}

.section ul {
  list-style-type: disc;
  padding: 0 10px 0 25px;
}

.section ul li {
  margin-bottom: 8px;
}

.task-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.prerequisites {
  margin-top: 20px;
}

.prerequisite-button {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.prerequisite-button:hover {
  background-color: #0056b3;
}

.prerequisite-button:focus {
  outline: none;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}

.checkbox-container label {
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
}

.checkbox-container ul {
  margin: 1rem;
}

.checkbox-container input[type="checkbox"] + label {
  cursor: pointer;
  color: #ffffff;
}

.checkbox-container input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 2px solid #ffffff;
  border-radius: 4px;
}

.checkbox-container input[type="checkbox"]:checked + label::before {
  background-color: #2adf12;
}

.step-title-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
}

.step-title-container h3 {
  margin: 0;
  font-size: 1.2rem; /* Adjust the font size as needed */
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type="checkbox"] + label {
  display: inline-block;
}

@media only screen and (max-device-width: 1024px) {
  .task-container {
    width: 30%;
    min-width: 150px;
    max-width: 70%;
  }
}
