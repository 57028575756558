*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  max-width: 100vw;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

button {
  cursor: pointer;
}

.App {
  width: 100%;
  min-height: 100vh;
  padding-top: 60px;
}

/* navbar */
.nav-bar {
  margin: 60px 0;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #494949;
  color: #242443;
  height: 50px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  border-bottom: none;
}



.sign-in {
  border: none;
  background-color: transparent;
}
.sign-in > img {
  margin: 20px;
  height: 40px;
  width: auto;
}


/* welcome page */
.welcome {
  padding: 30px;
  text-align: center;
  margin-top: 40px;
  color: #000000;
}
.welcome :is(h2, p, img) {
  margin-bottom: 20px;
}

main.welcome {
  background-color: #000;
}

/* chat component */
.chat-box {
  background-color: #000;
}

.messages-wrapper {
  padding: 80px 20px;
}
.chat-bubble {
  border-radius: 20px 20px 20px 0;
  padding: 15px;
  background-color: #000000;
  color: #ffffff;
  width: max-content;
  max-width: calc(100% - 50px);
  box-shadow: 3px 3px 1px 1px #6d246d;
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}



.chat-bubble.right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 20px 20px 0 20px;
  background-color: #333333;
  box-shadow: -2px 2px 1px 1px #fd77d0;
  width: 50%;
}
.chat-bubble__left {
  width: 50%;
  border-radius: 50%;
  margin-right: 10px;
}
.user-name {
  font-weight: bold;
  font-size: 1.2rem;
  color: #ffffff;
  text-align: right;
  text-decoration: underline;
  text-align: center;
}
p .user-name {
  padding: 20px;
}
.user-message {
  word-break: break-word;
  white-space: pre-wrap;
  color: #ffffff;
  padding: 1rem;
  font-size: 1.25rem;
}

.message-time {
  display: block;
  text-align: right;
  color: #fff;
}

/* send message */
.send-message {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px 30px;
  background-color: #000000;
  display: flex;
  border-top: 1px solid#8a8a8a;
}
.send-message > input {
  height: 40px;
  padding: 10px 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  flex-grow: 1;
  background-color: #333;
  color: #ffffff;
  font-size: 1rem;
}

.send-message > :is(input, button):focus {
  outline: none;
  border-bottom: 1px solid #7cc5d9;
}
.send-message > button {
  width: 70px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid #979797;
  background-color: #000000;
  font-weight: 600;
}

@media only screen and (max-device-width: 640px)  {
  .chat-bubble.right {
    width: 70%;
  }
  .chat-bubble {
    width: 70%;
    display: flex;
    justify-content: center;
  }

  #livechat-container > div > main > div > div:nth-child(2) > div > p.message-date {
    font-size: 0.75rem;
  }
  .user-name {
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
  }
  .user-message {
    font-size: 1rem;
  }

  .nav-bar {
    margin: 60px 0;
  }
}
